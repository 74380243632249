*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}
/* declaring theme colors */
:root{
    --white:#FFFFFF;
    --teal:#174471AA;
}

.OurVal{
    background-image: url('../images/MainPage/SwiperIMG/CARRUSEL1.png');
  background-position:right;
  background-size:cover;
  color: var(--white);
  height: auto;
  align-items: center;
  position: relative;
  
}

.list-group-item {
    text-transform:uppercase;
    color: var(--white);
    margin-bottom: 2rem;
    font-family: bebas-neue, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 21pt;
    background-color:transparent; 

}


.list-group-item:visited {
    background-color:var(--teal);
  
}


.list-group-item:hover {
    background-color:var(--teal);
    color: var(--white);
}

.list-group-item:active {
    background-color:var(--teal);
  
}

.valWrapper{
    /* margin-left: 5rem; */
    justify-content: center;
    padding: 5rem;
}

.ValueMessage{
  
    color: var(--white);
    font-size: 18pt;
    text-align:justify;
}

.WrapperMessage{
    background-color: var(--teal);
    color: var(--white);
    font-size: 18pt;
    text-align:justify;
    padding: 5rem;
    height: 30rem;
}

.WrapperList{
    background-color: var(--teal);
    color: var(--white);
    font-size: 18pt;
    /* padding: 5rem; */
    padding-left: 20rem;
    padding-top: 8rem;
    height: 30rem;
}

.WrapperListDots {
    background-color: var(--teal);
    font-size: 18pt;
  
    padding-top: 8rem;
    height: 30rem;
}


.Column{
    /* margin: 0px;
    padding: 0px; */
    margin-top: 2rem;
    /* height: 45rem; */
}

/* Estilo base para el contenedor del modal */
.custom-modal {
    width: 20rem;
    background-color: pink;
  }
  
  /* Estilo para el popover dentro del modal */
  .custom-popover {
    max-width: 80rem;
    background-color: #174471;
  }
  
  /* Estilo para la imagen dentro del popover */
  .custom-image {
    max-width: 30rem;
  }

  /* Agrega puntos antes de cada elemento de la lista */
    ul.list-with-dots {
    list-style: none;  /* deletes the bullet points */
  }
  
    ul.list-with-dots li::before {
    display: inline-block;
    margin-right: 0.5em; /* Espacio entre elementos de la lista */
    font-size: 1.2rem; /* Tamaño del punto (ajusta según sea necesario) */
  }

  @media (max-width: 768px) {
    .cart-container {
      transform: translateY(-60px); /* Ajusta este valor para mover el contenido hacia arriba */
    }    
  }

  @media (min-width: 768px) {
    ul.list-with-dots {
      padding-left: 20rem; /* Ajusta el valor de padding según tus necesidades */
      padding-right: 17rem;
    }
  }
  
  /* Media query para hacer que el modal sea responsivo en dispositivos más pequeños */
  @media (max-width: 768px) {
    .custom-modal {
      width: 100%;
      max-width: 90%;
    }
  
    .custom-popover {
      max-width: 100%;
    }
  
    /* Ajusta el tamaño máximo de la imagen en dispositivos más pequeños */
    .custom-image {
      max-width: 10rem;
    }
  }
  
  /* Media query para aumentar el tamaño de la imagen en vista de escritorio */
  @media (max-width: 768px) {
    /* Ajusta el tamaño máximo de la imagen en vista de escritorio */
    .custom-image {
      max-width: 100%; /* Puedes ajustar este valor según tus preferencias */
    }
  }

@media screen and (max-width: 768px) {
    .WrapperListDots {
        padding-left: 0.5rem; /* Elimina el padding izquierdo */
        padding-top: 0; /* Elimina el padding superior */
        height: auto; /* Altura automática */
    }
}

@media screen and (max-width:1024px) {
    /* .OurVal{
        height: 105vh;
    } */

    .WrapperMessage{
        /* padding-bottom: 105vh; */
    }
    
}
@media screen and (max-width:576px) {
    /* .OurVal{
        height: 150vh;
    } */

    .WrapperMessage{
        height: auto;
        padding: 1rem;
    }

    .valWrapper{
        /* margin-left: 5rem; */
        /* justify-content: center; */
        padding: 0px;
    }
    
}