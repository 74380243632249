*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.Cadena{
    margin:0px;
    padding: 0px, 20px;
    margin-top: 10px;
}




.legendValue{
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-family: bebas-neue,sans-serif;
    font-weight: 400;
    font-style: normal;
}