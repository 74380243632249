/* adding color variable  */
:root{
    --ryblue:#004aad;
    --white:#FFFFFF;
}
*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.legend{
    background-image:linear-gradient(to top, #FFFFFF60,#FFFFFF00),url("../images/MainPage/ponemostrial3.png");
    background-position:center;
    background-size:cover;
    height: 45vh;
    display: flex;
    justify-content: center;
    margin-top: 1vmax;
   
    
}
  
  .wrapping{
    position: relative;
    /* background: var(--ryblue); */
    width: 40rem;
    margin-bottom: 1rem;
  }

  .legendS{
    position: absolute;
    bottom: 0px;
    margin-left: 1vmax;
    padding: 2px 10px 0 10px;
  
    background-color: #47484F70;
    text-align: center; 
  
  }

  .legendS h1{
    text-transform: uppercase;
    font-family: bebas-neue, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40pt;
    color: var(--white);
  }