*,
*::before,
*::after {
    /* box-sizing: border-box; */
    padding: 0%;
    margin: 0%;
}

/* color Variables */

:root{
    --blue: #1c4874;
  --orange: #f5822a;
  --ltblue: #859db0;
  --rylblue: #004aad;
  --gray:#e0e3e6;
  --indigo:#1b4776;
}

.Ribbon3{
 background-image: linear-gradient(to right,#FFFFFFEE,#1b477600),url('../images/fondo_azul_degradado-removebg-preview.png');
  background-position:top;
  background-size:cover;
  color: var(--gray);
  height: auto;
  align-items: center;
}

.logo1 {
    max-width: 55vh;
    display: flex;
    /* margin: 20px; */
    margin-top: -100px;
}

.logomain {
    height: 11vh;
}

.SearchBar{
    /* padding-left: 5rem; */
}

.SearchButton{
    background-color: #f5822a;
}

.titles{
    /* font-size: small; */
    text-transform: uppercase;
}
.SecondaryLegend{
    font-size: 9pt;
    margin: 0px;
    padding: 0px;
}

.LoginAnchor{
    color: white;
}

.PhoneLegend{
    
    margin: 0px;
    padding: 0px;
}



.anchor span{
    font: 1.3rem/100% 'Roboto', sans-serif;
    text-transform: uppercase;
    padding-left: 4px;
    font-size: 11pt;
}

.form-select{
    /* add important to this element since it is the size of the category selection  */
    max-width: 19rem;
    padding-left: 8px; 
}

.SearchField{ 
    max-width: 15rem;
}

.input-group{   
    justify-content: end;
}

.lastcol{
    /* background-color: #f5822a; */
   

}

@media screen and (min-width:1400px) {
    .SearchBar{
        margin: 0px;
        padding: 0px;
        margin-left: 3rem;
        padding-left: 9rem;
    }
    
}

@media screen and (max-width:576px) {
    .input-group{
        justify-content:left;
    }
    .SearchBar{
        margin: 0px;
        padding: 0px;
    }
    .container-fluid{
        padding: 8px;
    }
    .form-select{
        font-size: x-small;
        max-width: 15rem;
    }
    .form-control{
        font-size: x-small;
    
        max-width: 12rem;
    }
    .logomain {
        height: 6vh;
    }
    .LoginAnchor{
    
      margin-left: 2rem;
    }
    .SearchBar{
        padding-right: 25px;
        padding-left: 0px;
        margin: 0px; 
    }

    .PhoneLegend{
        /* color: var(--indigo); */
    }
    .anchor {
        font-size: 0.6rem;
        /* color: var(--indigo); */
    }
    
    .anchor span{
        font-size: 0.7rem;
}
.SecondaryLegend{
    font-size: 0.6rem;
}
.Phonetag{
    margin: 0px 0px;
    padding: 0px 0px;
    text-align: left;
}

.PhoneLegend{
    font-size: .6rem;
    margin: 0px;
    padding-left: 2rem;
}

}





