/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

body{
  /* background:linear-gradient(225deg, #0E3659 0%, #DCEAED 100%);  */
  /* background: white; */
  color: #343434;
  overflow: auto;
  min-height: 100vh;
}

.VerOpt {
  /* background-color: orange; */
  padding-left: 0px;
  margin-left: 0px;
  /* display: flex;
  flex-direction: column; */
  text-transform: uppercase;

}
/* .VerOpt > span{
  padding-bottom: 20px;
} */
.prodWrapper{
  display: flex;
}

.catalog{
  display: flex;
  width: 78%;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5px;  
}

.NavTitles{
  background-color: #212529;
  font: 1.3rem/100% 'Roboto', sans-serif;
  color: #e0e3e6;
  letter-spacing: 2px;
  padding: .2rem;
  text-align: start;
  
}

.LegendMonedero{
  font: 1.3rem/100% 'Roboto', sans-serif;
}

.Monederobtn{
  background-color: #212529;
  letter-spacing: 3px;
  width: 10rem;
}

.MainLabels{
  text-align: left;
  margin: 0px, 0px;
  padding: 0px, 0px;
  text-transform: uppercase;
  font: 1rem/100% 'Roboto', sans-serif;
  font-size: 21pt;
  font-weight: bold;
  
  

}
.Cust-info{
  padding: 3rem;
  /* background-color: purple; */
  
}




.CardGrid{
  display: flex;
  margin-right: 0.25rem;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  
}
.CardGridT5{
  display: flex;
  margin-right: 0.5rem;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  
}

.CardGrid h1{
  text-align: left;
 
  text-transform: uppercase;
  font: 1rem/100% 'Roboto', sans-serif;
  font-size: 21pt;
  font-weight: bold;

}

.Top5Label{
  text-align: left;
  margin: 0px, 0px;
  padding: 0px, 0px;
  text-transform: uppercase;
  font: 1rem/100% 'Roboto', sans-serif;
  font-size: 21pt;
  font-weight: bold;

}

/* @media (min-width:810px) and (max-width:960px) { */
@media screen and (max-width:960px) {
  .VerOpt{
    width: 100%;
  }
  .NavTitles {
    text-align: center;
  }
  /* .Cust-info{
    text-align: center;
    justify-content: center;
  } */
}
