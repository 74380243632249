@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Raleway:wght@200;600&display=swap');


.Conveyor{
position: relative;   
height: 55rem;
align-items: center;
}

/* using property after to set the background opacity without alter the content  */
/* pay special attention to the positions of the before after and its children */
.Conveyor::before {
    content:"";
   background-image: url('../../components/images/MainPage/distribucion-por-producto.png');
   background-size: cover;
   background-position:center;
   position: absolute;
   top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.35;
    
    }

  

.contact-left  {
    text-transform: uppercase;
    position:relative;
  


}
.contact-left h1 {
    color: black;
    font-family: bebas-neue, sans-serif;
    font-weight:bold;
    font-size: 30pt;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 2rem;
}


.contact-left h4 {
    font: 600 1.5rem/150% 'Roboto', sans-serif;
    color: black; 
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 4rem;
}
.contact-left h5 {
    font: 600 1rem/150% 'Roboto condensed', sans-serif;
    color: black; 
    text-transform: uppercase;
    letter-spacing: 2px;
}


.rounded {
    background: transparent;
}


.contact-form {
    background-color: transparent;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.contact-form label {
    float: left;
    font: 600 1rem/150% 'Roboto Sans', sans-serif;
    margin: 0px 0px 0 0;
    
}

.contact-form label[aria-required="true"]::after {
    content: ' *';
    color: red;
}

.contact-form .form-control {
    border: 0;
    border-radius: 0;
    border-bottom:none;
}


.form-btn {

    margin: 0px,0px;
    padding: 0px,0px;
    border: 0;
    border-radius: 0;
    width:100%;
    background: #f47f24;
    font: 200 1.3rem/150% 'Roboto', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.contact-right{
position: relative;
}

.form-control{
    max-width: 100vh;
}