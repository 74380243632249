*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}


.RibbonBottom {
   
    padding: 80px;
    background-image: url('../../components/images/MainPage/SupplyChainNew.png');
    background-position: center;
    background-size: cover;
    width:90vmax;
    height:1vmax;

    @media(max-width:768px) {
      width:250px;
      height:auto;
  }
  }
  
  .RibbonFooter {
   
    background-color: #bec3c4;
    height: 25vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 3rem;
  }
  
  .RibbonFooter img {
    max-width: 15rem;
    margin: 12px 0;
  }
  
.Ficon {
    color:#C13584
  }

.Cfacebook{
    color:#4267B2; 
  }

.Ctwitter{
  color:#405DE6; 
}  
.Ctube{
  color:#ff0000
}

/* for white icons use this */

/* .ribbon-as > i,
.ribbon-as > a > i {
 color: #ffffff;
} */


  .BotRow {
    margin-bottom: 10px;
    justify-content: center;
    margin-left: -35px;
  }
  
  .privacy-tos {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column nowrap;
    background: black;
    color: gray;
  }
  
  .footer-links a:link,
  .footer-links a:active,
  .footer-links a:visited {
    color: gray;
    text-decoration: none;
    padding: 15px 30px;
    font: bold 1.3rem/100% 'Roboto', sans-serif;
    transition: all .5s ease;
  }
  
  .footer-links a:hover {
    color: var(--blue);
    transition: all .3s ease;
  }
  
  
span.ribbon-as i {
    font-size: 1.9rem;
  }
  
  span.ribbon-as i:nth-child(1) {
    padding-left: 30px;
  }
  
  .copyright {
    font: 0.8rem/100% 'Calibri', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    
  }

  .BlueCorner{
    
    float:inline-end;
  }
  .LastRibbon{
    margin: 0px, 0px;
    padding: 0px, 0px;
    width: 100%;
  }
  
.BlueCorner{
  position: absolute;
  right: 0;
  bottom:0;
  
}

.BlueCorner  p{

  display:none;
  /* display:inline-block; */

}

.BlueCorner h4{
  display:inline-block;
  text-transform: uppercase;
  font-size: smaller;
}

.BlueCorner:hover h4{ 
  display:none;
}

.BlueCorner:hover p{
  display:inline-block;
  /* display:none; */
}

.BlueCorner .Corner{
  background-color:#004aad;
  height: 6rem;
  width: 6rem;
 border-radius: 50%;
}

.Contenido h1{
 font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 1rem;
text-transform: uppercase;
}


.BlueCorner .Corner:hover {
  background-color:#004aad;
  height: 15rem;
  width: 18rem;
 border-top-left-radius: 100%;
 border-top-right-radius:0%;
 border-bottom-left-radius: 0%;
 border-bottom-right-radius: 0%;
} 

.Contenido{
  display: flex;
  justify-content: flex-end;
}


.logoBw{
  margin-top: 2rem;
  width: 4rem;

}
.ClickWhats{
  background-color: #25d366;
  text-transform: uppercase;
   border-radius: 15rem;
  
}

.ClickWhats span{
  margin-left: 0.5rem;
}





  @media screen and (max-width:576px) {

    .footer-links a:link,
    .footer-links a:active,
    .footer-links a:visited {
     
      font: bold 0.7rem/100% 'Roboto', sans-serif;
     
    }

  }