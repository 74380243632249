/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.Invoices{
    width: 100%;
  

}

.CardId{
    width: 13rem;
    text-transform: uppercase;
    font: 'Roboto', sans-serif;
    text-align: start;
    border: none;
}

.CardId h1{
    font-size: 18pt;
}

.CardId h4{
    font-size: 14pt;
    
}