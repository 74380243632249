*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.clickCalendar{
    background-color: #f47f24;
    border-radius: 20rem;
  }
  .clickCalendar span{
  
    margin-left: 0.5rem;
  }