.masthead, .dissclose {
background-color:#11131680;
/* background-color:#f7f7f7bb; */
color: #ffffff;
}

.color-overlay{
    /* position:absolute; */
    height: 100;
    width: 100;
    mix-blend-mode: normal;
}

.Cardlogo img{
    height: 7rem;
}

form {
    margin-top: 0px;
    background-color:#000000;
    box-shadow: 5px 5px 5px rgba(0,0,0,0,.2);
}

.button{
    margin-left: 80px;
    padding: 0px,100px,0px,20px;
}
.errorMessage {
    font-family: "Hanken-Book";
    color: yellow;
    font-size: 16px;
    font-style: italic;
    border-radius: 2px;
  }

  .LoginCard{
    position: relative;
    background-color: #f7f7f740;
  }

  .liga{
    color: gainsboro;
  }