*{
    margin: 0;
    padding: 0;
}

 /* controls slider inside swipper-wrapper */
.swiper-slide{
    display: block;
    width:100;
    height:30vh;
}

/* image size inside the swiper */
.swiper-slide img{
    display:block;
    width: auto;
    height:15vi;
    /* object-fit:cover; */
    @media(max-width:768px) {
      display: inline;
      object-fit: contain;
      width:100vi;
      height:30vh;
  } 
  @media(max-width:820px) {
  
    object-fit: contain;
    width:100vi;
    height:30vh;
} 

    
}

