@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Raleway:wght@200;600&display=swap');

/* color variables */
:root {
  --blue: #1c4874;
  --orange: #f5822a;
  --ltblue: #859db0;
  --rylblue: #004aad;
  --gray:#e0e3e6;
  --indigo:#1b4776;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}


/* navigation */
/* .navi {
  display: flex;
  justify-content: space-evenly;
} */

.nav-link{
    /* color: rgb(45, 55, 61) ; */
    color: var(--gray);
    /* margin: 0 65px; */
    font: 1.3rem/100% 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 8px #2c2c2c;
  
  
}
/* .mainNav{
  margin: 0px 285px;

} */



.dklk {
  color:var(--indigo);

}



.dklk:hover {
  color: white;
}

/* ribbon over the header */
.main-ribbon {
  /* background: var(--ltblue); */
  opacity:.65;
  width: 100%; 
  text-align: center; 
  /* padding: 15px;  */
  margin-bottom: 3rem;
  display: flex;
  color:var(--gray);
  /* border-bottom: 1px solid var(--blue); */
}

.topRow{
  padding-bottom: 0%;
}

.nav-ribbon ~ .ribbon-txt,
.nav-ribbon ~ span {
  color:var(--indigo);  
}

.ribbon-txt > i,
.ribbon-txt > a > i {
 
  margin: 3px 5px;
}

span.ribbon-txt::after {

  content: "";
  padding: 0 0.7rem;
}

span.ribbon-txt.rate{
  margin-left: 10px;
}

.left-rib {
  color:var(--indigo);
  text-align: center;
}
.UserId {
  text-align: right;
  margin-right: 90px;


}

.rate img{
  width:auto;
  height: 12px;
  object-fit: cover;
  margin-right: 15px;
}

.Flags{
  margin-top: 15px;
}

.left-rib img:nth-of-type(even) {
  margin-left: -10px;
}

.left-rib > i {
  margin: 0 10px; 
}


.right-rib {
  text-align: right;
}



 

.logobott{
 max-width: 30%;
}

.foto {
height: 40vh;
}

.anchor {
  text-decoration: none;
  color:var(--gray); 
}

.anchor:hover {
  color: var(--orange);
}

/* hero background */
.hero.container-fluid {
  background-image: url('../components/images/fondo-solarmax.jpg');
  background-position:left;
  background-size:cover;
  height:42vmax;
}

.Exchange {
  text-align: center;
  overflow-wrap: inherit;
}

.rate {
font-size: 12px;
margin-right: -10px;
}

.ribbon-txt.rate::after:nth-of-type(even) {
  content: '';
  padding: 0;
  margin-right: 10px;
}



.BrandCont{
  margin: 0px;
  padding:30px;
  margin-top: 1rem;
 
}

.NavCont{
  margin: 0px;
  padding: 0px;
 
}

/* classes to control the carousel from React bootstrap */
.SlideImg {
  height: auto;
}

.SlideCont{
  margin: 0px;
  padding: 0px;
  height: 85vh;
  /* this controls the overlap between swiper and carousel amazon clone */
  margin-bottom: -8rem;
}

.swipeCont{

  padding: 0px;
  margin:0px;

}






@media screen and (max-width:992px) {
  .swipeCont{
    /* this controls the overlap between swiper and carousel amazon clone */
      margin-top: -38rem;
      padding: 0px;
    
    }
  
}

@media screen and (max-width:576px) {
  .swipeCont{
    /* this controls the overlap between swiper and carousel amazon clone */
      margin-top: -33rem;
      padding: 0px;
    
    }
  
}

@media screen and (max-width:380px) {
  .swipeCont{
    /* this controls the overlap between swiper and carousel amazon clone */
      margin-top: -27rem;
      padding: 0px;
    
    }
  
}